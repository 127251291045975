import React, {useState, useContext, useEffect} from 'react'
import {Calendar as ReactCalendar} from 'react-calendar';
import StyledCalendar from './styled-calendar'
import 'react-calendar/dist/Calendar.css';
import { getFormatDate } from '../../lib/utils'
import moment from 'moment'
import { CalendarEventContext } from '../layout'

require('moment/locale/ja');
moment.locale('ja');


const DynamicCalendar = ({nextMonth, startDate, minDate, maxDate}) => {
  const { events } = useContext(CalendarEventContext)
  const [holidays, setHolidays] = useState(events)


  useEffect(() => {
    setHolidays(events.filter(event => {
      // console.log("event", event)
      return event.summary === "休診日"
    }).map(event => { return event.start.date}))
  },[events])


  const getTileContent = ({ date, view }) => {
    // 月表示のときのみ
    if (view !== 'month') {
      return null;
    }
    const day = getFormatDate(date);

    return (holidays.includes(day)) ? <p style={{color: "red"}}>休診</p>  : ''
  }
  const titleString = (nextMonth) => {
    if(nextMonth) {
      return `${nextMonth.getFullYear()}年${nextMonth.getMonth() + 1}月`
      // return title 
    } else {
      return `${startDate.getFullYear()}年${startDate.getMonth() + 1}月`
    }
  }
  return (
    <>
      <div style={{textAlign: "center"}}>  {titleString(nextMonth)} </div>
      <StyledCalendar>
        <ReactCalendar
          locale="ja-JP"
          calendarType="US"
          showNavigation={false}
          value={startDate}
          minDate={minDate}
          maxDate={maxDate}
          maxDetail='month'
          tileContent={getTileContent}
        />
      </StyledCalendar>
    </>
  )
}

export default DynamicCalendar

import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { Table } from 'antd';
import "antd/dist/antd.css";
import DynamicCalendar from '../components/common/dynamic-calendar'
import {getFirstDayOfNextMonth, getLastDayOfThisMonth, getLastDayOfNextMonth} from '../lib/utils'

import Layout from "../components/layout"
import Seo from "../components/seo"
import { ListItem } from '../components/common/lists'
import useHeadings from '../lib/useHeadings'
import Phone from '../components/common/phone'
import { schedule } from '../lib/tables'
import { StyledH3 } from '../styles/custom'


const GuidePage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  const tableOfContents = useHeadings();
  // console.log("tableOfContents", tableOfContents)

  return (
    <Layout location={location} title={siteTitle} toc={tableOfContents}>
    <Seo title="受診案内" />

    <h1 id="受診案内">受診案内</h1>
    <h2 id="再来診療">再来診療</h2>
    <StyledH3 id="診療時間">診療時間</StyledH3>

    <ListItem 
      items={[
        "平日(水曜をのぞく)は、以下の時間帯で診療しています",
        [
          "午前は、10:00〜12:30まで",
          " 午後は、14:00〜18:00まで(ただし金曜午後は、新患とオンライン診療のみ)"
        ],
        "土曜日は、午後4時まで診療しています",
        "定期的な休診日は、毎週水曜、日曜ならびに祝日です"
      ]}
    >
    </ListItem>


    <StyledH3 id="週間診療スケジュール">週間診療スケジュール</StyledH3>

    <p>
    再来(2回目以降の診察のこと)診療は、以下の週間スケジュールで実施しています。
    </p>

    <Table 
      dataSource={schedule.regular.dataSource} 
      columns={schedule.regular.columns} 
      size="small" 
      pagination={false} 
      bordered 
      style={{marginBottom: "1rem"}}
    />

    <StyledH3 id="月間診療スケジュール">月間診療スケジュール</StyledH3>

    <p>
    月間での診療スケジュールは、翌月分までは、下記のようになっています。
    なお、ゴールデンウィークとお盆と年末年始には、1週間程度の休診日をもうけています。
    </p>

    <DynamicCalendar 
      startDate={new Date()} 
      minDate={new Date()}
      maxDate={getLastDayOfThisMonth(new Date())}
    />
    <DynamicCalendar 
      nextMonth={new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1)}
      startDate={new Date()} 
      minDate={getFirstDayOfNextMonth(new Date())}
      maxDate={getLastDayOfNextMonth(new Date())}
    />

    <div id="closed-days"> </div>

    <h2 id="新患として受診する">新患として受診する</h2>

    <p>
    新患での診察は、<strong>予約が必要</strong>です。
    予約は、<strong>当日分のみ</strong>電話にて受け付けています。
    受診を希望する日の午前10時から正午12時までのあいだに、電話でご連絡ください。
    なお当日分の予約が埋まり次第、予約受付は終了となります。
    </p>

    <p>
    新患診察の開始時間は、以下の表のとおりです。
    </p>


    <Table 
      dataSource={schedule.newPatient.dataSource} 
      columns={schedule.newPatient.columns} 
      size="small" 
      pagination={false} 
      style={{marginBottom: "1rem"}}
      bordered 
    />

    予約の際には下記のような情報が必要となりますので、お知らせください。

    <ListItem 
    items={[
      "氏名", 
      "年齢", 
      "性別", 
      "電話番号",
      "現在の状況(1分程度で)"]}
    >
    </ListItem>

    その他、初診について気になる点があれば、<Link to="/faq#初診の手続きについて"> 初診の手続きについて</Link>のページを参照してもらうか、メールや電話にてお尋ねください。

    <Phone id="phone"></Phone>


    </Layout>
  )
}

export default GuidePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
    // allFile {
    //   edges {
    //     node {
    //       name
    //       childImageSharp {
    //         gatsbyImageData(layout: CONSTRAINED)
    //       }
    //     }
    //   }
    // }
